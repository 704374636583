#main-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-image: var(--main-gradient);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    text-align: center;
    padding: 0px 20px;
    height: var(--main-header-size);
    transition: height 100ms ease-out;
    z-index: 1;
}

#main-header.extended {
    height: var(--main-header-size-extended);
}
#main-header.collapsed {
    height: var(--main-header-size-collapsed);
}

.main-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-top: 4px;
}

.main-header-logo {
    position: relative;
    right: 5px;
    padding: 15px 0 5px 0;
}

.main-header-title {
    position: relative;
    color: white;
    fill: white;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.1);
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

#menu-button{
    padding: 10px 5px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

#menu-return-button {
    position: absolute;
    left: 0;
    bottom: 12px;
    padding: 10px
}

.main-header-title {
    height: 61px;
}

.main-header-notify-icon {
    position: relative;
    cursor: pointer;
    width: 23px;
}

.main-header-notify-number {
    color: white;
    background-color: tomato;
    border-radius: 50%;
    font-size: 10px;
    height: 14px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 6;
    pointer-events: none;
}

.main-header-notify-icon.animate {
    animation: ring 250ms;
    animation-iteration-count: 5;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-15deg);
    }
    80% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
