#main-app {
    width: 100%;
    height: 100%;
    transition: padding-top 100ms ease-out;
    padding: 0;
    text-align: center;
}

#main-app.with-header {
    padding-top: calc(var(--main-header-size))
}
#main-app.with-header-collapsed {
    padding-top: calc(var(--main-header-size-collapsed))
}
#main-app.with-header-extended {
    padding-top: calc(var(--main-header-size-extended))
}

.card {
    flex: 1;
    padding: 40px 20px 20px 20px;
    box-shadow: var(--default-shadow);
    border-radius: 8px;
    position: relative;
}

.card-title {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 22px;
    font-weight: bold;
}

.page {
    padding: 20px;
}

.info-block-container {
    display: flex;
}

.info-block {
    position: relative;
    padding: 20px;
    border: 1px solid var(--gray);
    border-radius: 8px;
    width: 130px;
    height: 100px;
    margin: 10px;
    box-shadow: var(--default-shadow);
}

.info-block label {
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    position: relative;
    top: -10px;
    left: -10px;
}

.info-block span {
    font-size: 32px;
    color: var(--dark-gray);
}

.graph-block {
    padding: 30px 0;
}

.graph-block label {
    font-weight: bold;
    padding: 20px 0;
}

.flex-container {
    display: flex;
    justify-content: space-around;
}





