.card-button {
    width: 148px;
    min-width: 148px;
    height: 140px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: var(--darker-shadow);
    border: 1px solid var(--light-grey);
    cursor: pointer;
    user-select: none;
    margin: 10px;
    text-align: left;
    position: relative;
}
.card-button:hover {
    box-shadow: var(--green-shadow);
}

.card-button-label {
    color: var(--gray);
    font-size: 16px;
    height: 44px;
    width: 100px;
    position: absolute;
    top: 52px;
}

.card-button-value {
    font-size: 26px;
    color: #555;
    position: absolute;
    bottom: 15px;
    left: 20px;
}

.card-button-icon {
    position: absolute;
    top: 15px;
    left: 20px;
}

