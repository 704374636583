.overlay {
    display: none;
    z-index: 2;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.overlay.show{
    display: block;
}
