* {
  box-sizing: border-box;
}

html{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  width: 100%;
  height: 100%;
  font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  text-rendering: optimizeLegibility;
  font-stretch: 100%;
  color: var(--text-color);
  background-color: #fbfbfb;
  overflow: hidden !important;
  overflow-y: auto !important;
}

#root {
  width: 100%;
  height: 100%;
}


