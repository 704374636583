#status-page {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 10px;
}

.card-button {
    flex: 1;
    max-width: 180px;
}

.flex-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-top: 20px;
}

#icon-rest, #icon-app {
    fill: var(--itb-green)
}

.status-info-card {
    position: relative;
    padding: 10px 5px 0px 0px;
    min-width: 350px;
    flex: 1;
}

.status-info-card svg {
    position: absolute;
    top: 10px;
    left: 10px;
    fill: var(--text-color);
}
.status-info-card .subtitle span, .status-info-graph .subtitle span {
    position: absolute;
    top: 10px;
    left: 35px;
    color: var(--text-color);
    font-size: 16px;
    white-space: nowrap;
    font-weight: normal;
}

.status-info-graph {
    position: relative;
    margin-top: 20px;
}
.status-info-graph .subtitle {
    width: 100%;
    text-align: left;
}
.status-info-graph .subtitle svg {
    position: relative;
    bottom: 2px;
    left: 8px;
    fill: var(--text-color);
    margin-right: -20px;
}

#status-graph-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 5px;
    margin: 10px;
}
@media screen and (min-width: 500px){
    #status-graph-legend {
        justify-content: center;
    }
}

#status-graph-legend .legend {
    display: flex;
    margin: 5px 20px;
}
#status-graph-legend .legend label{
    font-weight: bold;
    margin-right: 5px;
}

.legend:before {
    content: '';
    border-radius: 100%;
    width: 10px;
    height: 10px;
    position: relative;
    top: 3px;
    right: 4px;
}
#total:before {
    background-color:#d70206;
}
#online:before {
    background-color: #f05b4f;
}
#associated:before {
    background-color: #f4c63d;
}